<script>

    import HomeIcon from 'vue-feather-icons/icons/HomeIcon'
    import FileIcon from 'vue-feather-icons/icons/FileIcon'
    import SunIcon from 'vue-feather-icons/icons/SunIcon'
    import MoonIcon from 'vue-feather-icons/icons/MoonIcon'
    import DiscIcon from 'vue-feather-icons/icons/DiscIcon'
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon'
    import RotateCwIcon from 'vue-feather-icons/icons/RotateCwIcon'
    import XIcon from 'vue-feather-icons/icons/XIcon'
    import ArrowUpIcon from 'vue-feather-icons/icons/ArrowUpIcon'
    import SettingsIcon from 'vue-feather-icons/icons/SettingsIcon'
    import HeartIcon from 'vue-feather-icons/icons/HeartIcon'
    import MenuIcon from 'vue-feather-icons/icons/MenuIcon'
    import BellIcon from 'vue-feather-icons/icons/BellIcon'
    import SearchIcon from 'vue-feather-icons/icons/SearchIcon'
    import UserIcon from 'vue-feather-icons/icons/UserIcon'
    import LogOutIcon from 'vue-feather-icons/icons/LogOutIcon'
    import CircleIcon from 'vue-feather-icons/icons/CircleIcon'
    import MonitorIcon from 'vue-feather-icons/icons/MonitorIcon'
    import ActivityIcon from 'vue-feather-icons/icons/ActivityIcon'
    import ShoppingCartIcon from 'vue-feather-icons/icons/ShoppingCartIcon'
    import Share2Icon from 'vue-feather-icons/icons/Share2Icon'
    import EditIcon from 'vue-feather-icons/icons/EditIcon'
    import PlusIcon from 'vue-feather-icons/icons/PlusIcon'
    import Trash2Icon from 'vue-feather-icons/icons/Trash2Icon'
    import AlertOctagonIcon from 'vue-feather-icons/icons/AlertOctagonIcon'
    import AlertTriangleIcon from 'vue-feather-icons/icons/AlertTriangleIcon'
    import CheckCircleIcon from 'vue-feather-icons/icons/CheckCircleIcon'
    import TrendingUpIcon from 'vue-feather-icons/icons/TrendingUpIcon'
    import FilePlusIcon from 'vue-feather-icons/icons/FilePlusIcon'
    import AlertCircleIcon from 'vue-feather-icons/icons/AlertCircleIcon'
    import PlayIcon from 'vue-feather-icons/icons/PlayIcon'
    import PauseIcon from 'vue-feather-icons/icons/PauseIcon'
    import StopCircleIcon from 'vue-feather-icons/icons/StopCircleIcon'
    import WatchIcon from 'vue-feather-icons/icons/WatchIcon'
    import RadioIcon from 'vue-feather-icons/icons/RadioIcon'
    import MapPinIcon from 'vue-feather-icons/icons/MapPinIcon'
    import CheckSquareIcon from 'vue-feather-icons/icons/CheckSquareIcon'
    import BookmarkIcon from 'vue-feather-icons/icons/BookmarkIcon'
    import BriefcaseIcon from 'vue-feather-icons/icons/BriefcaseIcon'
    import FileTextIcon from 'vue-feather-icons/icons/FileTextIcon'
    const icons = {
        HomeIcon,
        FileIcon,
        SunIcon,
        DiscIcon,
        MoonIcon,
        ChevronDownIcon,
        RotateCwIcon,
        ArrowUpIcon,
        XIcon,
        SettingsIcon,
        HeartIcon,
        MenuIcon,
        BellIcon,
        SearchIcon,
        UserIcon,
        LogOutIcon,
        CircleIcon,
        MonitorIcon,
        ActivityIcon,
        ShoppingCartIcon,
        Share2Icon,
        EditIcon,
        PlusIcon,
        Trash2Icon,
        AlertOctagonIcon,
        AlertTriangleIcon,
        CheckCircleIcon,
        TrendingUpIcon,
        FilePlusIcon,
        AlertCircleIcon,
        PlayIcon,
        PauseIcon,
        StopCircleIcon,
        WatchIcon,
        RadioIcon,
        MapPinIcon,
        CheckSquareIcon,
        BookmarkIcon,
        BriefcaseIcon,
        FileTextIcon
    }

    export default {
        name: 'FeatherIcon',
        functional: true,
        props: {
            icon: {
                required: true,
                type: [String, Object]
            },
            size: {
                type: String,
                default: '14'
            },
            badge: {
                type: [String, Object, Number],
                default: null
            },
            badgeClasses: {
                type: [String, Object, Array],
                default: 'badge-primary'
            }
        },
        render(h, { props, data }) {
            // Create Feather Icon
            const svg = h(icons[props.icon], { props: { size: props.size }, ...data })

            // If no badge is provided => Render just SVG
            if (!props.badge) return svg

            // Badge
            const badgeEl = h('span', { staticClass: 'badge badge-up badge-pill', class: props.badgeClasses }, [props.badge])

            // Return span with children
            return h('span', { staticClass: 'feather-icon position-relative' }, [svg, badgeEl])
        }
    }
</script>

<style lang="scss">
svg.feather {
  outline: none
}
</style>
