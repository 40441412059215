const Status = {
    Planed: 1,
    Assigned: 2,
    InProgress: 3,
    Stopped: 4,
    Finished: 5
}

const TaskWorkingTimeStatus = {
    Running: 1,
    Stopped: 2,
    Deleted: 3
}

export default { Status, TaskWorkingTimeStatus }
