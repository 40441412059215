import Vue from 'vue'

const ConnectionRequestType = {
    NewNumber: 1,
    NumberPorting: 2
}

const ActivationType = {
    BSAActivationWithoutInstallationOfActiveEquipmentOfISPUser: 1,
    BSAActivationWithInstallationOfActiveEquipmentOfISPUser: 2,
    ReactivationOfBSAAfterTemporaryShutdown: 3,
    RelocationOfBSA: 4,
    SpeedChange: 5
}

const ServiceInquiryType = {
    PriceAndConnectionPeriod: 1,
    AnotherQuery: 2
}

const ResolveIssueType = {
    AccessThreadError: 1,
    UnavailabilityOfEquipment: 2
}

const OptOutType = {
    TemporaryDisconnection: 1,
    ChangeSpeedOrAddress: 2,
    PermanentDisconnection: 3
}

Vue.prototype.$bitstreamRequest = { ConnectionRequestType, ActivationType, ServiceInquiryType, ResolveIssueType, OptOutType }

export default { ConnectionRequestType, ActivationType, ServiceInquiryType, ResolveIssueType, OptOutType }