import Vue from 'vue'

const Category = {
    NewCommentOnRequestFromISPUser: 1,
    ChangedStatusOnRequest: 2,
    ChangedRequestMadeByOperatorOnRequest: 3,
    NewRequest: 4,
    NewIncident: 5,
    FinishedIncident: 6
}

Vue.prototype.$NotificationCategory = Category

export default {Category}