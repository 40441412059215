import { $themeBreakpoints } from '@themeConfig'
import axios from '@/libs/axios'
import Vue from 'vue'
import i18n from '@/libs/i18n'
import {localize} from 'vee-validate'
import dayjs from 'dayjs'

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,
        showLoader: false,
        mapCenter: '',
        turnstileSiteKey: '',
        locale: localStorage.getItem('locale') || 'hr',
        currentTimestamp: 0,
        currentTimestampInterval: null,
        googleMapsApiKey:'',
        counties: []
    },
    getters: {
        currentBreakPoint: state => {
            const { windowWidth } = state
            if (windowWidth >= $themeBreakpoints.xl) return 'xl'
            if (windowWidth >= $themeBreakpoints.lg) return 'lg'
            if (windowWidth >= $themeBreakpoints.md) return 'md'
            if (windowWidth >= $themeBreakpoints.sm) return 'sm'
            return 'xs'
        },
        mapCenter(state) {
            return state.mapCenter
        },
        locale(state) {
            return state.locale
        },
        googleMapsApiKey(state) {
            return state.googleMapsApiKey
        },
        counties(state) {
            return state.counties
        }
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
        START_LOADING(state) {
            state.showLoader = true
        },
        STOP_LOADING(state) {
            state.showLoader = false
        },
        SET_MAP_CENTER(state, token) {
            state.mapCenter = token
        },
        SET_TURNSTILE_SITE_KEY(state, siteKey) {
            state.turnstileSiteKey = siteKey
        },
        SET_LOCALE(state, locale) {
            state.locale = locale
            i18n.locale = locale
            localize(locale)
            dayjs.locale(locale)
        },
        SET_GOOGLE_MAPS_API_KEY(state, mapsApiKey) {
            state.googleMapsApiKey = mapsApiKey
        },
        SET_COUNTIES(state, counties) {
            state.counties = counties
        }
    },
    actions: {
        async setConfigs({commit}) {
            try {
                const response = await axios.get('/api/user/v1/configs/turnstile_site_key')
                commit('SET_TURNSTILE_SITE_KEY', response.data.turnstile_site_key)
            } catch (error) {
                Vue.prototype.$printError(Vue.prototype.$parseError(error, i18n.t('general.error_load_data')))
            }
        },
        async setMapConfigs({commit}) {
            try {
                const mapCenter = axios.get('/api/user/v1/configs/map_center')
                const googleMapsApiKeyResponse = axios.get('/api/user/v1/configs/google_maps_api_key')
                const countiesResponse = axios.get('/api/user/v1/configs/counties')

                const responses = await Promise.all([mapCenter, googleMapsApiKeyResponse, countiesResponse])
                commit('SET_MAP_CENTER', responses[0].data.map_center)
                commit('SET_GOOGLE_MAPS_API_KEY', responses[1].data.google_maps_api_key)
                commit('SET_COUNTIES', responses[2].data)
            } catch (error) {
                Vue.prototype.$printError(Vue.prototype.$parseError(error, i18n.t('general.error_load_data')))
            }
        },
        startCurrentTimestampInterval({state}) {
            state.currentTimestampInterval = setInterval(() => {
                state.currentTimestamp = Number((new Date().getTime() / 1000).toFixed(0))
            }, 1000)
        },
        stopCurrentTimestampInterval({state}) {
            if (state.currentTimestampInterval) {
                clearInterval(state.currentTimestampInterval)
                state.currentTimestamp = 0
            }
        }
    }
}
