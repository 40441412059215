import i18n from '@/libs/i18n'
import request from '@/libs/enums/request'
import {RequestChangeType} from '@/libs/enums/request/change'
import Vue from 'vue'

function getTypes() {
    return [
        { text: i18n.t('hubs.hub'), value: request.Type.Hub },
        { text: i18n.t('bitstream.bitstream'), value: request.Type.Bitstream },
        { text: i18n.t('connection.connection'), value: request.Type.Connection }
    ]
}

function getRequestTypes() {
    return [
        { text: i18n.t('request.connection_request'), value: request.RequestType.InstallConnection, toString() { return this.text } },
        { text: i18n.t('request.service_inquiry'), value: request.RequestType.ServiceInquiry, toString() { return this.text }  },
        { text: i18n.t('request.request_for_removal_of_disturbance'), value: request.RequestType.ResolveIssue, toString() { return this.text }  },
        { text: i18n.t('request.request_for_the_excluded'), value: request.RequestType.OptOut, toString() { return this.text }  }
    ]
}

export function getRequestStatuses() {
    return [
        { text: i18n.t('request.opened'), value: request.RequestStatus.Opened, color: 'light-info' },
        { text: i18n.t('request.accepted'), value: request.RequestStatus.Accepted, color: 'light-secondary' },
        { text: i18n.t('request.blocked'), value: request.RequestStatus.Blocked, color: 'light-warning' },
        { text: i18n.t('request.rejected'), value: request.RequestStatus.Rejected, color: 'light-danger' },
        { text: i18n.t('request.finished'), value: request.RequestStatus.Finished, color: 'light-success' },
        { text: i18n.t('request.resolved'), value: request.RequestStatus.Resolved, color: 'success' },
        { text: i18n.t('request.deleted'), value: request.RequestStatus.Deleted, color: 'danger' }
    ]
}

function getRequestChangedText() {
    return `${i18n.t('request_change.changed')} ${i18n.t('request_change.field_value')}`
}

function getRequestChangeTypes(requestType, type) {
    return [
        { text: `${i18n.t('request_change.sent_a_request')}`, value: RequestChangeType.Create },
        { text: `${getRequestChangedText()} ${i18n.t('isp.operator')}`, value: RequestChangeType.ISPId },
        { text: `${getRequestChangedText()} ${i18n.t('team.team')}`, value: RequestChangeType.TeamId },
        { text: `${getRequestChangedText()} ${i18n.t('request.request_made_by_operator')}`, value: RequestChangeType.RequestMadeByOperator },
        { text: `${i18n.t('request_change.changed')} ${i18n.t('request_change.request_status')}`, value: RequestChangeType.Status },
        { text: `${getRequestChangedText()} ${i18n.t('request.documentation')}`, value: RequestChangeType.Documents },
        { text: `${getRequestChangedText()} ${i18n.t('request.start')}`, value: RequestChangeType.Start },
        { text: `${getRequestChangedText()} ${i18n.t('request.end')}`, value: RequestChangeType.End },
        { text: `${getRequestChangedText()} ${requestType === request.RequestType.InstallConnection ? i18n.t('service.type_of_connection') : i18n.t('request.request_type')}`, value: RequestChangeType.RequestOptionTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.connection_method')}`, value: RequestChangeType.ConnectionTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.planned_topology')}`, value: RequestChangeType.PlannedTopologyTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.cable_capacity_ru_number')}`, value: RequestChangeType.CableCapacityRUNumberTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.number_of_splitters_for_installation')}`, value: RequestChangeType.NumberOfSplittersForInstallationTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.number_of_ru_splitters')}`, value: RequestChangeType.NumberOfSplittersTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.number_of_possible_new_connections')}`, value: RequestChangeType.NumberOfPossibleNewConnections },
        { text: `${getRequestChangedText()} ${i18n.t('request.remark')}`, value: RequestChangeType.Remark },
        { text: `${getRequestChangedText()} ${i18n.t('request.connection_date')}`, value: RequestChangeType.ConnectionDate },
        { text: `${getRequestChangedText()}`, value: RequestChangeType.Description },
        { text: `${getRequestChangedText()} ${i18n.t('request.number_of_threads')}`, value: RequestChangeType.NumberOfOpticalFibersTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.split_rate')}`, value: RequestChangeType.SplitRate },
        { text: `${getRequestChangedText()} ${i18n.t('request.number_of_ru_for_network_and_splitters')}`, value: RequestChangeType.NumberOfRUForNetworkAndSplittersTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.operator_id')}`, value: RequestChangeType.ServiceId },
        { text: `${getRequestChangedText()} ${i18n.t('request_change.resolving_date')}`, value: RequestChangeType.ResolvingDate },
        { text: `${getRequestChangedText()} ${i18n.t('request.brief_description_of_the_activities_undertaken_and_conclusion')}`, value: RequestChangeType.ConclusionsAndActionsTakenDescription },
        { text: `${getRequestChangedText()} ${i18n.t('request.number_of_removing_potential_connections')}`, value: RequestChangeType.NumberOfRemovedConnections },
        { text: `${getRequestChangedText()} ${i18n.t('request.operator_id')}`, value: RequestChangeType.RequestId },
        { text: `${getRequestChangedText()} ${i18n.t('request.activation_type')}`, value: RequestChangeType.ActivationTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.customer_service')}`, value: RequestChangeType.BitstreamPackageId },
        { text: `${getRequestChangedText()} ${i18n.t('request.installation_of_active_equipment_of_user_customer')}`, value: RequestChangeType.InstallationOfActiveEquipmentOfISPUser },
        { text: `${getRequestChangedText()} ${i18n.t('user.phone')}`, value: RequestChangeType.Phone },
        { text: `${getRequestChangedText()} ${i18n.t('request.service')}`, value: RequestChangeType.ServiceType },
        { text: `${getRequestChangedText()} ${i18n.t('request.splitter_port')}`, value: RequestChangeType.NumberOfSplitter },
        { text: `${i18n.t('request_change.added_comment')}:`, value: RequestChangeType.Message },
        { text: `${getRequestChangedText()} ${i18n.t('request.date_of_acception_of_request')}`, value: RequestChangeType.DateOfAcceptance },
        { text: `${getRequestChangedText()} ${i18n.t('request.date_of_rejection_of_request')}`, value: RequestChangeType.DateOfRejection },
        { text: `${getRequestChangedText()} ${i18n.t('request.date_of_finished_of_request')}`, value: RequestChangeType.DateOfFinished },
        { text: `${getRequestChangedText()} ${type === request.Type.Bitstream ? i18n.t('request.customer_service') : i18n.t('request.service')}`, value: RequestChangeType.ServiceTypeId },
        { text: `${getRequestChangedText()} ${i18n.t('request.request_type')}`, value: RequestChangeType.RequestTypeId }
    ]
}

export function getRequestStatus(status) {
    const statuses = getRequestStatuses()
    const index = statuses.findIndex(x => x.value === status)
    if (index > -1) return statuses[index]
    return { text: '', value: 0, color: '' }
}

export function getRequestChangeText(typeOfChange, requestType, type) {
    const requestChanges = getRequestChangeTypes(requestType, type)
    const index = requestChanges.findIndex(x => x.value === typeOfChange)
    if (index > -1) return requestChanges[index].text
    return ''
}

Vue.prototype.$types = getTypes
Vue.prototype.$requestTypes = getRequestTypes
Vue.prototype.$requestStatuses = getRequestStatuses

export default {getTypes, getRequestTypes, getRequestStatuses}