const Type = {
    Bitstream: 1,
    Hub: 2,
    Connection: 3
}

const RequestType = {
    InstallConnection: 1,
    ServiceInquiry: 2,
    ResolveIssue: 3,
    OptOut: 4
}

const RequestStatus = {
    Opened: 1,
    Accepted: 2,
    Blocked: 3,
    Rejected: 4,
    Finished: 5,
    Resolved: 6,
    Deleted: 7
}

const RequestOptions = {
    RequestType:1,
    QueryType:2,
    IssueType:3,
    ExclusionType:4,
    ActivationType:5,
    ConnectionMethod:6,
    PlannedTopology:7,

    CableCapacityRUNumber:8,
    NumberOfSplittersForInstallation:9,
    NumberOfRUSplitters:10,
    NumberOfRUForNetworkAndSplitters:11,
    NumberOfThreads:12
}

export default { Type, RequestType, RequestStatus, RequestOptions}