import Vue from 'vue'
import i18n from '@/libs/i18n'
import permissions from '@/permissions'

const type = {
    Requests: 1,
    ISPAddresses: 2,
    Incidents: 3,
    Tasks: 4,
    RunningIncidents: 5,
    ISPRequests: 6,
    RequestTable: 7
}

function cardNames() {
    return [
        { text: i18n.t('request.requests'), value: type.Requests },
        { text: i18n.t('general.list_of_addresses_for_each_operator'), value: type.ISPAddresses },
        { text: i18n.t('general.list_of_incidents'), value: type.Incidents },
        { text: i18n.t('general.list_of_tasks'), value: type.Tasks },
        { text: i18n.t('general.list_of_running_incidents'), value: type.RunningIncidents },
        { text: i18n.t('general.list_of_requests_for_each_operator'), value: type.ISPRequests },
        { text: i18n.t('general.requests_table'), value: type.RequestTable }
    ]
}

function cardName(value) {
    const index = cardNames().findIndex(x => x.value === value)
    return index > -1 ? cardNames()[index].text : ''
}

function checkPermission(value) {
    switch (value) {
        case type.Requests:
            return Vue.prototype.$checkPermissions([permissions.ReadHubRequest, permissions.ReadBitstreamRequest, permissions.ReadConnectionRequest, permissions.ReadAndWriteAllRequests])
        case type.RequestTable:
            return Vue.prototype.$checkPermissions([permissions.ReadHubRequest, permissions.ReadBitstreamRequest, permissions.ReadConnectionRequest, permissions.ReadAndWriteAllRequests])
        case type.ISPAddresses:
            return Vue.prototype.$checkPermissions([permissions.ReadISP]) && (Vue.prototype.$hasPermission(permissions.ReadConnection) || Vue.prototype.$hasPermission(permissions.ReadBitstream))
        case type.Incidents:
            return Vue.prototype.$checkPermissions([permissions.ReadAllIncidents, permissions.ReadOwnIncidents])
        case type.Tasks:
            return Vue.prototype.$checkPermissions([permissions.ReadTeamTask]) && Vue.prototype.$hasPermission(permissions.ReadAllCalendarTask)
        case type.RunningIncidents:
            return Vue.prototype.$checkPermissions([permissions.ReadAllIncidents, permissions.ReadOwnIncidents])
        case type.ISPRequests:
            return Vue.prototype.$checkPermissions([permissions.ReadISP]) &&
                Vue.prototype.$checkPermissions([permissions.ReadHubRequest, permissions.ReadBitstreamRequest, permissions.ReadConnectionRequest, permissions.ReadAndWriteAllRequests])
        default:
            return false
    }
}

Vue.prototype.$controlPanel = { type, cardNames, cardName, checkPermission }

export default { type }