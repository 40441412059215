import i18n from '@/libs/i18n'
import incident from '@/libs/enums/incident'
import Vue from 'vue'

function getTypes() {
    return [
        { text: i18n.t('hubs.hub'), value: incident.Type.Hub },
        { text: i18n.t('addresses.bitstream_or_connection'), value: incident.Type.Addresses },
        { text: i18n.t('incident.custom_address'), value: incident.Type.CustomAddress }
    ]
}

function getIncidentStatuses() {
    return [
        {text:i18n.t('incident.incident_not_resolved'), value: incident.Status.IncidentStatusPaused},
        {text:i18n.t('incident.incident_in_progress'), value: incident.Status.IncidentStatusStarted},
        {text:i18n.t('incident.incident_resolved'), value: incident.Status.IncidentStatusStopped},
        {text:i18n.t('incident.incident_finished'), value: incident.Status.IncidentStatusFinished}

    ]
}

Vue.prototype.$typesOnIncident = getTypes
Vue.prototype.$incidentStatuses = getIncidentStatuses

export default {getTypes, getIncidentStatuses}