export const RequestChangeType = {
    Create: 1,
    ISPId: 2,
    TeamId: 3,
    RequestMadeByOperator: 4,
    Status: 5,
    Documents: 6,
    Start: 7,
    End: 8,
    RequestOptionTypeId: 9,
    ConnectionTypeId: 10,
    PlannedTopologyTypeId: 11,
    CableCapacityRUNumberTypeId: 12,
    NumberOfSplittersForInstallationTypeId: 13,
    NumberOfSplittersTypeId: 14,
    NumberOfPossibleNewConnections: 15,
    Remark: 16,
    ConnectionDate: 17,
    ContractObligation: 18,
    Description: 19,
    NumberOfOpticalFibersTypeId: 20,
    SplitRate: 21,
    NumberOfRUForNetworkAndSplittersTypeId: 22,
    ServiceId: 23,
    ResolvingDate: 24,
    ConclusionsAndActionsTakenDescription: 25,
    NumberOfRemovedConnections: 26,
    RequestId: 27,
    ActivationTypeId: 28,
    BitstreamPackageId: 29,
    InstallationOfActiveEquipmentOfISPUser: 30,
    Phone: 31,
    ServiceType: 32,
    NumberOfSplitter: 33,
    Message: 34,
    DateOfAcceptance: 35,
    DateOfRejection: 36,
    DateOfFinished: 37,
    ServiceTypeId: 38,
    RequestTypeId: 39
}

export default { RequestChangeType }