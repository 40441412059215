import Vue from 'vue'
import i18n from '@/libs/i18n'
import addresses from '@/libs/enums/addresses'

function getConnectionStatuses() {
    return [
        {label:i18n.t('connections.active'), value: addresses.connectionStatus.Active},
        {label:i18n.t('connections.additional'), value: addresses.connectionStatus.Additional},
        {label:i18n.t('connections.preparatory'), value: addresses.connectionStatus.Preparatory}
    ]
}

Vue.prototype.$connectionStatuses = getConnectionStatuses

export default {getConnectionStatuses}
