import Vue from 'vue'

Vue.prototype.$localesList =  [
    {
        locale: 'hr',
        img: require('@/assets/images/flags/hr.png'),
        name: 'Hrvatski'
    },
    {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English'
    }
]

