const ConversionType = {
    HRTS96ToWGS84 :1,
    WGS84ToHRTS96:2
}

const ConvertedType = {
    WGS84:1,
    HRTS96:2

}

export default {ConversionType, ConvertedType}