import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faAirFreshener } from '@fortawesome/free-solid-svg-icons/faAirFreshener'
import { faAmbulance } from '@fortawesome/free-solid-svg-icons/faAmbulance'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch'
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus'
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import {faEdit} from '@fortawesome/free-solid-svg-icons/faEdit'
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faFile, faTasks } from '@fortawesome/free-solid-svg-icons'
import {faSquare} from '@fortawesome/free-solid-svg-icons/faSquare'
import {faPlay} from '@fortawesome/free-solid-svg-icons/faPlay'
import {faPause} from '@fortawesome/free-solid-svg-icons/faPause'
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus'
import {faDownload} from '@fortawesome/free-solid-svg-icons/faDownload'
import {faCopy} from '@fortawesome/free-solid-svg-icons/faCopy'
import {faHome} from '@fortawesome/free-solid-svg-icons/faHome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight'
import {faRoute} from '@fortawesome/free-solid-svg-icons/faRoute'
import {faStopwatch} from '@fortawesome/free-solid-svg-icons/faStopwatch'
import {faArrowDown} from '@fortawesome/free-solid-svg-icons/faArrowDown'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import {faFlagCheckered} from '@fortawesome/free-solid-svg-icons/faFlagCheckered'
import {faWrench} from '@fortawesome/free-solid-svg-icons/faWrench'
import {faStar} from '@fortawesome/free-solid-svg-icons/faStar'
import {faLocationArrow} from '@fortawesome/free-solid-svg-icons/faLocationArrow'
import {faRecycle} from '@fortawesome/free-solid-svg-icons/faRecycle'
import {faBell} from '@fortawesome/free-solid-svg-icons/faBell'

/**
 * če vam meče ven error Uncaught TypeError: Cannot read properties of undefined (reading 'prefix')
 * je zelo verjetno krivo to, da nimate ikone prav poimenovane v importu
 * AirFreshnener namesto faAirFreshener (manjka fa)
 * faAirFreshner namesto faAirFreshener (typo)
 */

library.add(
    faAirFreshener,
    faAmbulance,
    faTimes,
    faCalendar,
    faInfoCircle,
    faSearch,
    faPlus,
    faMinus,
    faQuestionCircle,
    faEdit,
    faTrash,
    faCheckCircle,
    faExclamationCircle,
    faSquare,
    faPlay,
    faPause,
    faExclamationCircle,
    faEye,
    faMapMarkerAlt,
    faDownload,
    faMapMarkerAlt,
    faFile,
    faCopy,
    faTasks,
    faCopy,
    faHome,
    faArrowRight,
    faRoute,
    faStopwatch,
    faArrowDown,
    faArrowLeft,
    faFlagCheckered,
    faWrench,
    faStar,
    faLocationArrow,
    faRecycle,
    faBell
)

// Register the component globally
Vue.component('fa', FontAwesomeIcon)
