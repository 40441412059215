import Vue from 'vue'
import i18n from '@/libs/i18n'

const ServiceType = {
    OneTime: 1,
    Permanent: 2
}

const Status = {
    Active: 1,
    Inactive: 2
}

export function getServicePaymentTypes() {
    return [
        { text: i18n.t('service.one_time_service'), value: ServiceType.OneTime },
        { text: i18n.t('service.permanent_service'), value: ServiceType.Permanent }
    ]
}

Vue.prototype.$service = {Status, ServiceType}

export default {Status, ServiceType}