const ServiceType = {
    MakingAvailableAndConnectingOpticalFibers: 1
}

const ConnectionRequestType = {
    NewNumber: 1,
    NumberPorting: 2
}

const ServiceInquiryType = {
    PriceAndConnectionPeriod: 1,
    AnotherQuery: 2
}

const ResolveIssueType = {
    AccessThreadError: 1,
    UnavailabilityOfUserEquipment: 2,
    AdditionalMeasurementRequest: 3
}

export default { ServiceType, ConnectionRequestType, ServiceInquiryType, ResolveIssueType }