import Vue from 'vue'

const inUseType = {
    Free: 1,
    Bitstream: 2,
    Connection: 3
}

const connectionStatus = {
    Active: 1,
    Additional: 2,
    Preparatory: 3
}

const importStatus = {
    ToImport: 1,
    ToUpdate: 2
}

Vue.prototype.$addresses = { inUseType, connectionStatus, importStatus }

export default { inUseType, connectionStatus, importStatus }