import Vue from 'vue'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import calendar from 'dayjs/plugin/calendar'
import updateLocale from 'dayjs/plugin/updateLocale'
import localeData from 'dayjs/plugin/localeData'
import localeHr from 'dayjs/locale/hr'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(updateLocale)
dayjs.extend(calendar)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.locale(localeHr)
dayjs.extend(weekOfYear)
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)

dayjs.updateLocale('en', {
    calendar: {
        sameDay: 'HH:mm, [Today]',
        lastDay: 'HH:mm, [Yesterday]',
        lastWeek: 'HH:mm, [Last] dddd',
        sameElse: 'HH:mm, DD.MM.YYYY'
    }
})
dayjs.updateLocale('hr', {
    calendar: {
        sameDay: 'HH:mm, [Danas]',
        lastDay: 'HH:mm, [Jučer]',
        lastWeek: 'HH:mm, [Posljednji] dddd',
        sameElse: 'HH:mm, DD.MM.YYYY'
    }
})

const formatSeconds = (totalSeconds) => {
    const h = Math.floor(totalSeconds / 3600)
    const m = Math.floor(totalSeconds % 3600 / 60)
    const s = Math.floor(totalSeconds % 3600 % 60)
    if (h > 99) return `${(`0${  h}`).slice(-3)  }:${  (`0${  m}`).slice(-2)  }:${  (`0${  s}`).slice(-2)}`
    else return `${(`0${  h}`).slice(-2)  }:${  (`0${  m}`).slice(-2)  }:${  (`0${  s}`).slice(-2)}`
}

Vue.prototype.$formatSeconds = formatSeconds
Vue.prototype.$dayjs = dayjs

export default dayjs
