import Vue from 'vue'

const Status = {
    Active: 1,
    Additional: 2,
    Preparatory: 3
}

Vue.prototype.$connection = {Status}

export default { Status }