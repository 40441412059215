const ConnectionRequestType = {
    InitialApproach: 1,
    Expansion: 2
}

const ConnectionType = {
    WithoutMergingAndRentingThreads: 1,
    RentalOfThreadsFromTheConnectingInfrastructureNetwork: 2,
    PullingOwnSVKCable: 3
}

const PlannedTopologyType = {
    PTMP: 1,
    PTP: 2
}

const ServiceInquiryType = {
    PriceAndConnectionPeriod: 1,
    AnotherQuery: 2
}

const ResolveIssueType = {
    AccessThreadError: 1,
    UserOutageOnDC: 2,
    AdditionalMeasurementRequest: 3
}

export default { ConnectionRequestType, ConnectionType, PlannedTopologyType, ServiceInquiryType, ResolveIssueType }