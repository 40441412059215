import Vue from 'vue'

const formatAddress = (address) => {
    if (!address) return ''
    if (address.is_apartment) return `${address.street} ${address.street_number} ${address.additional_street_number} ${address.additional_street_number_supplement}, ${address.floor} ${address.apartment}, ${address.zip} ${address.city}`
    else return `${address.street} ${address.street_number} ${address.additional_street_number} ${address.additional_street_number_supplement}, ${address.zip} ${address.city}`
}

const scrollToAnchor = (routeHash) => {
    if (routeHash) {
        const element = document.querySelector(routeHash)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
            setTimeout(() => {
                window.scrollBy(0, -100)
            }, 500)
        }
    }
}

const formatPrice = (value) => {
    if (value) return `${Number(Number(value) / 1000000).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })} €`
    return `${Number(0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })} €`
}

const getAttachmentName = (originalName) => {
    if (originalName.length > 0) {
        const splitName = originalName.split('_')
        if (splitName.length === 2) return splitName[0]
    }
    return originalName
}

Vue.prototype.$formatAddress = formatAddress
Vue.prototype.$scrollToAnchor = scrollToAnchor

export {formatAddress, scrollToAnchor, formatPrice, getAttachmentName}
