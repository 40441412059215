import i18n from '@/libs/i18n'
import Vue from 'vue'
import ticket from '@/libs/enums/ticket'

function getTicketTypes() {
    return [
        { text: i18n.t('bitstream.bitstream'), value: ticket.Type.Bitstream },
        { text: i18n.t('hubs.hub'), value: ticket.Type.Hub },
        { text: i18n.t('connection.connection'), value: ticket.Type.Connection},
        { text: i18n.t('request.request'), value: ticket.Type.Request}

    ]
}

Vue.prototype.$ticketTypes = getTicketTypes

export default {getTicketTypes}