const Type = {
    Addresses: 1,
    Hub: 2,
    CustomAddress: 3
}

const IncidentType = {
    Incident: 1,
    Warning: 2,
    Note: 3
}

const Status = {
    IncidentStatusPaused: 1,
    IncidentStatusStarted: 2,
    IncidentStatusStopped: 3,
    IncidentStatusFinished:4
}

export default {Type, IncidentType, Status}