import i18n from '@/libs/i18n'
import Vue from 'vue'

const Type = {
    Bitstream: 1,
    Hub: 2,
    Connection: 3,
    Request:4
}

const Status = {
    Finished: 1,
    InProgress: 2,
    NotFinished: 3
}

function getTicketStatuses() {
    return [
        { text: i18n.t('hubs.resolved'), value: Status.Finished },
        { text: i18n.t('hubs.in_resolving'), value: Status.InProgress },
        { text: i18n.t('hubs.not_resolved'), value: Status.NotFinished }
    ]
}

Vue.prototype.$ticketStatuses = getTicketStatuses

export default { Type, Status }