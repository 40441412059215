<template>
    <div
        id="app"
        class="h-100"
        :class="[skinClasses]"
    >
        <component :is="layout">
            <router-view />
        </component>
    </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
    import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
    import { watch } from '@vue/composition-api'
    import useAppConfig from '@core/app-config/useAppConfig'

    import { useWindowSize, useCssVar } from '@vueuse/core'

    import store from '@/store'

    const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
    const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
    const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

    export default {
        components: {

            // Layouts
            LayoutHorizontal,
            LayoutVertical,
            LayoutFull

        },
        data() {
            return {
                loadPermissionsInterval: null,
                loadIncidentAndTaskWorkingTimesInterval: null,
                loadNotificationsInterval: null
            }
        },
        // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
        // Currently, router.currentRoute is not reactive and doesn't trigger any change
        computed: {
            layout() {
                if (this.$route.meta.layout === 'full') return 'layout-full'
                return `layout-${this.contentLayoutType}`
            },
            contentLayoutType() {
                return this.$store.state.appConfig.layout.type
            },
            locale() {
                return this.$store.getters['app/locale']
            }
        },
        beforeCreate() {
            // Set colors in theme
            const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = colors.length; i < len; i++) {
                $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
            }

            // Set Theme Breakpoints
            const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = breakpoints.length; i < len; i++) {
                $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
            }

            // Set RTL
            const { isRTL } = $themeConfig.layout
            document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
        },
        methods: {
            async loadPermissions() {
                await this.$store.dispatch('user/updateUserPermissions')

                this.loadPermissionsInterval = setInterval(function() {
                    this.$store.dispatch('user/updateUserPermissions')
                }.bind(this), 15000)
            },
            async loadRunningWorkingTime() {

                await this.$store.dispatch('user/getRunningIncidentWorkingTimes')
                await this.$store.dispatch('user/getRunningTaskWorkingTimes')

                this.loadIncidentAndTaskWorkingTimesInterval = setInterval(function() {
                    if (!this.$store.state.user.loggedIn) return
                    this.$store.dispatch('user/getRunningIncidentWorkingTimes')
                    this.$store.dispatch('user/getRunningTaskWorkingTimes')
                }.bind(this), 3000)
            },
            setLocale() {
                const userData = JSON.parse(localStorage.getItem('userData'))
                if (userData && userData.locale) {
                    this.$store.commit('app/SET_LOCALE', userData.locale)
                }
            },
            async loadNotifications() {
                await this.$store.dispatch('user/getUnreadNotifications')

                this.loadNotificationsInterval = setInterval(function() {
                    this.$store.dispatch('user/getUnreadNotifications')
                }.bind(this), 10000)
            }
        },
        setup() {
            const {skin, skinClasses} = useAppConfig()

            // If skin is dark when initialized => Add class to body
            if (skin.value === 'dark') document.body.classList.add('dark-layout')

            // Set Window Width in store
            store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
            const {width: windowWidth} = useWindowSize()
            watch(windowWidth, val => {
                store.commit('app/UPDATE_WINDOW_WIDTH', val)
            })

            return {
                skinClasses
            }
        },

        async mounted() {
            this.setLocale()
            await this.$store.dispatch('app/startCurrentTimestampInterval')
            await this.loadPermissions()
            await this.$store.dispatch('user/loadUserData')
            await this.$store.dispatch('app/setConfigs')
            await this.$store.dispatch('app/setMapConfigs')
            await this.$store.dispatch('user/setIsUserISP')
            await this.loadRunningWorkingTime()
            await this.loadNotifications()
        },
        beforeDestroy() {
            if (this.loadPermissionsInterval) clearInterval(this.loadPermissionsInterval)
            if (this.loadIncidentAndTaskWorkingTimesInterval) clearInterval(this.loadIncidentAndTaskWorkingTimesInterval)
            if (this.loadNotificationsInterval) clearInterval(this.loadNotificationsInterval)
            this.$store.dispatch('app/stopCurrentTimestampInterval')
        },
        watch: {
            '$store.state.app.showLoader'(val) {
                const appLoading = document.getElementById('loading-bg')
                if (appLoading) {
                    if (val) appLoading.style.display = 'block'
                    else appLoading.style.display = 'none'
                }
            }
        }
    }
</script>

<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
@import 'src/@core/scss/base/components/_variables-dark.scss';

body.dark-layout #loading-bg {
  background-color: rgba($theme-dark-body-bg, 0.8) !important;
}
#loading-bg {
  background-color: rgba($body-bg, 0.8) !important;
}
</style>
