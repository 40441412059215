import store from '@/store/index'
import Vue from 'vue'

const permissions = {
    // settings
    ReadUsersAndRoles: 101,
    WriteUsersAndRoles: 102,
    ReadISPUsers: 103,
    WriteISPUsers: 104,
    DEPRECATED_ReadCompanyInfo: 105,
    DEPRECATED_WriteCompanyInfo: 106,

    ReadControlPanel: 201,

    // čvorište
    ReadHub: 301,
    DEPRECATED_ReadHubActiveService: 302,
    ReadHubRequest: 303,
    WriteHubRequest: 304,
    ReadHubTicket: 305,
    WriteHubTicket: 306,
    WriteHub: 307,

    // priključci
    ReadConnection: 401,
    DEPRECATED_ReadConnectionActiveService: 402,
    ReadConnectionRequest: 403,
    WriteConnectionRequest: 404,
    ReadConnectionTicket: 405,
    WriteConnectionTicket: 406,

    // bitstream
    ReadBitstream: 501,
    DEPRECATED_ReadBitstreamActiveService: 502,
    ReadBitstreamRequest: 503,
    WriteBitstreamRequest: 504,
    ReadBitstreamTicket: 505,
    WriteBitstreamTicket: 506,

    // zahtjeva
    ReadAndWriteAllRequests: 601,
    ChangeRequestStatus: 602,
    ReadRequestTicket:603,
    WriteRequestTicket:604,

    // incident

    ReadOwnIncidents  : 701,
    WriteOwnIncidents : 702,

    ToggleIncidentTime: 703,
    ReadIncidentTag: 704,
    WriteIncidentTag: 705,
    ReadIncidentContact: 706,
    WriteIncidentContact: 707,

    ReadAllIncidents: 708,
    WriteAllIncidents: 709,
    DeleteAllIncidentWorkingTimes: 710,
    DeleteOwnIncidentWorkingTimes: 711,

    ReadIncidentType: 712,
    WriteIncidentType: 713,

    // grafovi
    ReadSNMPChart: 801,
    DEPRECATED_ReadBitstreamChart: 802,

    // Operateri
    ReadISP: 901,
    WriteISP: 902,
    ReadAPIKey: 903,
    WriteAPIKey: 904,

    // Plan rada
    ReadAllCalendarTask: 1001,
    WriteAllCalendarTask: 1002,
    ReadTeams: 1003,
    WriteTeams: 1004,
    ReadTeamTask: 1005,
    WriteTeamTask: 1006,
    ReadEmployee: 1007,
    DEPRECATED_WriteEmployee: 1008,
    ReadTeamTags: 1009,
    WriteTeamTags: 1010,
    ReadTaskType: 1011,
    WriteTaskType: 1012,
    WriteOwnCalendarTask: 1013,
    ReadTaskWorkingTime: 1014,
    WriteOwnTaskWorkingTime: 1015,
    WriteAllTaskWorkingTime: 1016,

    // Ticket
    // ReadHubTicket(305), ReadConnectionTicket(405), ReadBitstreamTicket(505)
    // WriteHubTicket(306), WriteConnectionTicket(406), WriteBitstreamTicket(506)
    ReadTicketName: 1101,
    WriteTicketName: 1102,
    ReadAndWriteAllTickets: 1103,
    ReadOwnTickets: 1104,
    ChangeAllTicketStatuses:1105,

    // eSkladište
    ReadWarehouse: 1201,
    WriteWarehouse: 1202,

    // Dobavljač
    ReadSupplier: 1301,
    WriteSupplier: 1302,

    // ONT
    ReadONT: 1401,
    WriteONT: 1402,

    // OLT
    ReadOLTConfiguration: 1501,
    WriteOLTConfiguration: 1502,

    // imports
    ReadOwnImports: 1601,
    ReadAllImports: 1602,
    WriteImports: 1603,

    // usluge
    ReadService: 1701,
    WriteService: 1702,

    // bitstream paketi
    ReadBitstreamPackage: 1801,
    WriteBitstreamPackage: 1802,

    // request options
    ReadRequestOptions  : 1901,
    WriteRequestOptions : 1902,

    //addresses
    WriteAddresses:1903

}

function hasPermission(permission) {
    if (!store.state.user.permissions || !store.state.user.permissions.permissions) return false
    return store.state.user.permissions.permissions.includes(permission)
}

function checkPermissions(requiredPermission) {
    if (requiredPermission) {
        if (typeof requiredPermission === 'number') {
            if (hasPermission(requiredPermission)) return true
        } else if (typeof requiredPermission === 'object') {
            for (const permission of requiredPermission) {
                if (hasPermission(permission)) return true
            }
        }
    }
    return false
}

function hasAllPermissions(requiredPermission) {
    if (requiredPermission) {
        if (typeof requiredPermission === 'number') {
            if (hasPermission(requiredPermission)) return true
        } else if (typeof requiredPermission === 'object') {
            for (const permission of requiredPermission) {
                if (!hasPermission(permission)) return false
            }
            return true
        }
    }
    return false
}

function hasPermissionGroup(requiredPermission) {
    //OPTIONS
    //requiredPermission: 201 //single permission
    //requiredPermission: [[201, 202]] //permission group with AND
    //requiredPermission: [[201, 202], [203, 204]] //multiple permission group with OR
    let permissionGroup = true
    // first check if group has requiredPermission set
    if (requiredPermission) {
        if (typeof requiredPermission === 'number') {
            if (hasPermission(requiredPermission)) return true
        } else if (typeof requiredPermission === 'object') {
            for (const permissionArr of requiredPermission) {
                permissionGroup = true
                if (typeof permissionArr === 'object') {
                    for (const permission of permissionArr) {
                        if (!hasPermission(permission)) {
                            permissionGroup = false
                        }
                    }
                }
                if (permissionGroup) return true
            }
            return false
        }
    }
}

Vue.prototype.$hasPermission = hasPermission
Vue.prototype.$checkPermissions = checkPermissions
Vue.prototype.$hasAllPermissions = hasAllPermissions
Vue.prototype.$hasPermissionGroup = hasPermissionGroup
Vue.prototype.$permissions = permissions

export default permissions
